@charset "UTF-8";
/* ==========================================================================


 	Protein Stylesheet for Monitor app

	Date: November 2016
	Authors: Jonathan & Shaun

	monitor.prote.in


========================================================================== */
@font-face {
  font-family: 'HelveticaNeueLight';
  src: url("https://protein-global-assets.s3.amazonaws.com/protein-radar/clients/sonos/fonts/HelveticaNeueLight.eot");
  src: url("https://protein-global-assets.s3.amazonaws.com/protein-radar/clients/sonos/fonts/HelveticaNeueLight.eot?#iefix") format("embedded-opentype"), url("https://protein-global-assets.s3.amazonaws.com/protein-radar/clients/sonos/fonts/HelveticaNeueLight.woff") format("woff"), url("https://protein-global-assets.s3.amazonaws.com/protein-radar/clients/sonos/fonts/HelveticaNeueLight.otf") format("opentype"), url("https://protein-global-assets.s3.amazonaws.com/protein-radar/clients/sonos/fonts/HelveticaNeueLight.svg#font") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'HelveticaNeueMedium';
  src: url("https://protein-global-assets.s3.amazonaws.com/protein-radar/clients/sonos/fonts/HelveticaNeueMedium.eot");
  src: url("https://protein-global-assets.s3.amazonaws.com/protein-radar/clients/sonos/fonts/HelveticaNeueMedium.eot?#iefix") format("embedded-opentype"), url("https://protein-global-assets.s3.amazonaws.com/protein-radar/clients/sonos/fonts/HelveticaNeueMedium.woff") format("woff"), url("https://protein-global-assets.s3.amazonaws.com/protein-radar/clients/sonos/fonts/HelveticaNeueMedium.otf") format("opentype"), url("https://protein-global-assets.s3.amazonaws.com/protein-radar/clients/sonos/fonts/HelveticaNeueMedium.svg#font") format("svg");
  font-weight: normal;
  font-style: normal; }

/* ==========================================================================

  $$$$$$$\                       $$\               $$\           
  $$  __$$\                      $$ |              \__|          
  $$ |  $$ | $$$$$$\   $$$$$$\ $$$$$$\    $$$$$$\  $$\ $$$$$$$\  
  $$$$$$$  |$$  __$$\ $$  __$$\\_$$  _|  $$  __$$\ $$ |$$  __$$\ 
  $$  ____/ $$ |  \__|$$ /  $$ | $$ |    $$$$$$$$ |$$ |$$ |  $$ |
  $$ |      $$ |      $$ |  $$ | $$ |$$\ $$   ____|$$ |$$ |  $$ |
  $$ |      $$ |      \$$$$$$  | \$$$$  |\$$$$$$$\ $$ |$$ |  $$ |
  \__|      \__|       \______/   \____/  \_______|\__|\__|  \__|
                                                               
 
  Protein Global Stylesheet

  Date: September 2016
  Authors: Jonathan & Shaun

  www.prote.in                                                
                                                               

========================================================================== */
/* ==========================================================================
  Reset                                          
========================================================================== */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

html {
  -webkit-font-smoothing: antialiased;
  overflow-y: visible !important; }

body {
  overflow-x: hidden;
  opacity: 1 !important; }

*:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

img {
  vertical-align: bottom;
  max-width: 100%; }

input, textarea, select {
  font: inherit; }

.group:before,
.group:after {
  content: "";
  display: table; }

.group:after {
  clear: both; }

.group {
  zoom: 1;
  /* For IE 6/7 (trigger hasLayout) */ }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

/* ==========================================================================
  Colors                                         
========================================================================== */
/* ==========================================================================
  Mixins                                          
========================================================================== */
.larger-title .text h2 {
  font-size: 24px;
  margin: 0 0 0.5em; }

.larger-title .text p, .larger-title .text .link {
  font-size: 16px; }

/* ==========================================================================
  Grid                                       
========================================================================== */
.container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box; }

.column,
.columns {
  width: 100%;
  float: left;
  box-sizing: border-box; }

/* For devices larger than 550px */
@media (min-width: 550px) {
  .column,
  .columns {
    margin: 0 1.5%;
    margin-top: 30px;
    margin-bottom: 30px; }
  .center-block {
    margin: 30px auto;
    float: none; }
  .one.column,
  .one.columns {
    width: 4.66666666667%; }
  .two.columns {
    width: 13.3333333333%; }
  .three.columns {
    width: 22%; }
  .four.columns {
    width: 30.3333333333%; }
  .five.columns {
    width: 37.3333333333%; }
  .six.columns {
    width: 47%; }
  .seven.columns {
    width: 56.6666666667%; }
  .eight.columns {
    width: 65.3333333333%; }
  .nine.columns {
    width: 74.0%; }
  .ten.columns {
    width: 82.6666666667%; }
  .eleven.columns {
    width: 91.3333333333%; }
  .twelve.columns {
    width: calc(100% - 1.5% - 1.5%); }
  .one-third.column {
    width: 30.6666666667%; }
  .two-thirds.column {
    width: 65.3333333333%; }
  .one-half.column {
    width: 48%; }
  /* Offsets */
  .offset-by-one.column,
  .offset-by-one.columns {
    margin-left: 8.66666666667%; }
  .offset-by-two.column,
  .offset-by-two.columns {
    margin-left: 17.3333333333%; }
  .offset-by-three.column,
  .offset-by-three.columns {
    margin-left: 26%; }
  .offset-by-four.column,
  .offset-by-four.columns {
    margin-left: 34.6666666667%; }
  .offset-by-five.column,
  .offset-by-five.columns {
    margin-left: 43.3333333333%; }
  .offset-by-six.column,
  .offset-by-six.columns {
    margin-left: 52%; }
  .offset-by-seven.column,
  .offset-by-seven.columns {
    margin-left: 60.6666666667%; }
  .offset-by-eight.column,
  .offset-by-eight.columns {
    margin-left: 69.3333333333%; }
  .offset-by-nine.column,
  .offset-by-nine.columns {
    margin-left: 78.0%; }
  .offset-by-ten.column,
  .offset-by-ten.columns {
    margin-left: 86.6666666667%; }
  .offset-by-eleven.column,
  .offset-by-eleven.columns {
    margin-left: 95.3333333333%; }
  .offset-by-one-third.column,
  .offset-by-one-third.columns {
    margin-left: 34.6666666667%; }
  .offset-by-two-thirds.column,
  .offset-by-two-thirds.columns {
    margin-left: 69.3333333333%; }
  .offset-by-one-half.column,
  .offset-by-one-half.columns {
    margin-left: 52%; } }

.description-block {
  width: 25%;
  margin: 30px 4.6% 30px 2%; }

.first {
  margin-left: 0 !important; }

/* ==========================================================================
  Graphics & Icons                                       
========================================================================== */
.logo {
  display: inline-block;
  position: relative;
  margin-left: 0;
  text-indent: -9999px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

#loading-bar .bar {
  background: #666666; }

#loading-bar-spinner {
  display: none; }

.loading {
  display: block;
  background-color: #F9F9F9;
  position: fixed;
  text-align: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 90; }

.loading-animation {
  position: relative;
  top: 45%; }
  .loading-animation svg path, .loading-animation svg rect {
    fill: #EFEFEF; }
  .loading-animation .shape {
    animation: dance 1s ease alternate infinite; }
  .loading-animation .shape-a {
    animation-delay: 0; }
  .loading-animation .shape-b {
    animation-delay: 0.2s; }
  .loading-animation .shape-c {
    animation-delay: 0.4s; }

@keyframes dance {
  0% {
    height: 10px;
    transform: translateY(10px); }
  50% {
    height: 15px;
    transform: translateY(-10px); }
  100% {
    height: 10px;
    transform: translateY(10px); } }

.material-icons {
  color: #555555; }

.rolling {
  background-image: url("https://protein-global-assets.s3.amazonaws.com/bootstrap/rolling.svg");
  width: 30px;
  height: 30px;
  background-size: cover;
  margin: -30px auto 0;
  top: 50%;
  position: relative; }

.redirect {
  position: relative;
  top: 40%; }

.fade.ng-enter,
.fade.ng-leave {
  -webkit-transition: all 1s ease;
  transition: all 1s ease; }

.fade.ng-enter {
  opacity: 0; }

.fade.ng-enter-active {
  opacity: 1; }

.fade.ng-leave {
  opacity: 1; }

.fade.ng-leave-active {
  opacity: 0; }

/* ==========================================================================
  Font Includes                                          
========================================================================== */
@font-face {
  font-family: 'pg-book';
  src: url("https://protein-global-assets.s3.amazonaws.com/fonts/PostGrotesk-Book.eot");
  src: url("https://protein-global-assets.s3.amazonaws.com/fonts/PostGrotesk-Book.eot?#iefix") format("embedded-opentype"), url("https://protein-global-assets.s3.amazonaws.com/fonts/PostGrotesk-Book.woff") format("woff"), url("https://protein-global-assets.s3.amazonaws.com/fonts/PostGrotesk-Book.otf") format("opentype"), url("https://protein-global-assets.s3.amazonaws.com/fonts/PostGrotesk-Book.svg#font") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Iconic Icons';
  font-style: normal;
  font-weight: 400;
  src: url("https://protein-global-assets.s3.amazonaws.com/fonts/Material-Design-Iconic-Font.eot");
  src: local("Material Icons"), local("Material-Design-Iconic-Font"), url("https://protein-global-assets.s3.amazonaws.com/fonts/Material-Design-Iconic-Font.woff2") format("woff2"), url("https://protein-global-assets.s3.amazonaws.com/fonts/Material-Design-Iconic-Font.woff") format("woff"), url("https://protein-global-assets.s3.amazonaws.com/fonts/Material-Design-Iconic-Font.ttf") format("truetype"); }

.material-icons, .iconic-icons {
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  position: relative;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga'; }

.material-icons {
  font-family: 'Material Icons'; }

.iconic-icons {
  font-family: 'Iconic Icons'; }

.material-icons.md-18, .zmdi.md-18 {
  font-size: 18px; }

.material-icons.md-24, .zmdi.md-24 {
  font-size: 24px; }

.material-icons.md-28, .zmdi.md-28 {
  font-size: 28px; }

.material-icons.md-36, .zmdi.md-36 {
  font-size: 36px; }

.material-icons.md-48, .zmdi.md-48 {
  font-size: 48px; }

/* ==========================================================================
  Typography                                    
========================================================================== */
body {
  font-family: "HelveticaNeueLight", sans-serif;
  font-size: 17px; }

h1 {
  font-size: 1.8em;
  font-family: "HelveticaNeueLight", sans-serif;
  color: #444444;
  line-height: 1.3;
  margin: 0.4em 0 0.4em; }

h2 {
  font-size: 1.3em;
  font-family: "HelveticaNeueMedium", sans-serif;
  color: #444444;
  margin: 0.5em 0;
  line-height: 1.3; }

h3 {
  font-size: 1.1em;
  color: #555555;
  margin: 0.5em 0;
  line-height: 1.3; }

h4 {
  font-size: 0.9em;
  color: #555555;
  margin: 0.5em 0; }

p {
  font-size: 1em;
  color: #666666;
  line-height: 1.65;
  margin: 0.2em 0 1.4em; }

.super-size {
  font-family: "HelveticaNeueLight", sans-serif;
  font-size: 3.6em;
  line-height: 1.1; }

.small {
  font-size: 0.9em;
  line-height: 1.5; }

.subscript {
  font-size: 12px;
  position: relative;
  top: -15px;
  margin-left: 3px; }

/* ==========================================================================
  Utilities & Helpers                                        
========================================================================== */
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0; }

.clearfix {
  display: inline-block; }

/* start commented backslash hack \*/
* html .clearfix {
  height: 1%; }

.clearfix {
  display: block; }

md-backdrop {
  position: fixed; }

hr {
  height: 2px;
  width: 100%;
  max-width: 1200px;
  border: none;
  margin: 0 auto;
  padding: 0;
  background-color: #EFEFEF;
  position: relative; }

hr.thin {
  height: 1px; }

hr.transparent {
  background-color: transparent; }

hr.short {
  width: 320px; }

hr.dashed {
  border-top: 2px dashed #EFEFEF;
  background-color: #FFFFFF; }

hr.grid {
  width: calc(100% - 2% - 2%);
  margin: 0 auto; }

.spacer {
  margin: 15px auto;
  height: 2px; }

.not-last:last-of-type hr.dashed {
  display: none; }

small, .small {
  font-size: 0.8em; }

strong, b, .bold {
  font-family: "HelveticaNeueMedium", sans-serif;
  font-weight: normal; }

.letter-spacing {
  letter-spacing: -1px; }

.underline {
  border-bottom: 1px solid;
  display: inline-block;
  padding-bottom: .1em;
  margin-bottom: 10px;
  line-height: 1em; }

.white-text {
  color: #FFFFFF; }

.italic {
  font-style: italic; }

.click {
  cursor: pointer; }

.float-left {
  float: left; }

.float-right {
  float: right; }

.center-text {
  text-align: center; }

.right-text {
  text-align: right; }

.left-text {
  text-align: left; }

.reflect {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1); }

.no-margin, hr.no-margin {
  margin: 0; }

.remove-text {
  text-indent: -9999px; }

.vert-align {
  position: relative;
  top: 45%;
  transform: translateY(-50%); }

.hide-on-mobile {
  display: block; }

.show-on-mobile {
  display: none; }

.hidden {
  opacity: 0; }

.article-block-element {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -khtml-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.visible {
  opacity: 1; }

.dot {
  font-size: 7px;
  margin: 0 10px;
  bottom: 1px; }

.quote-mark {
  quotes: "“" "”" "‘" "’"; }

.quote-mark:before {
  content: open-quote; }

.quote-mark:after {
  content: close-quote; }

/* ==========================================================================
  Alert styles
========================================================================== */
.sweet-alert {
  font-family: "HelveticaNeueLight", sans-serif; }
  .sweet-alert h2 {
    font-family: "HelveticaNeueMedium", sans-serif;
    margin: 25px 0 10px; }
  .sweet-alert .sa-button-container {
    text-align: center; }
  .sweet-alert button {
    font-family: "HelveticaNeueLight", sans-serif;
    margin: 30px 5px;
    padding: 7px 20px 5px;
    width: 150px;
    border-radius: 3px; }
  .sweet-alert .confirm {
    background-color: rgba(20, 174, 125, 0.9) !important;
    box-shadow: none !important; }

/* ==========================================================================
  Navigation & Menus                                         
========================================================================== */
header#main-header-wrapper {
  width: 100%;
  height: auto;
  top: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -khtml-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border-bottom: 0;
  position: fixed;
  z-index: 50;
  padding-bottom: 10px; }
  header#main-header-wrapper #main-header {
    width: calc(100% - 16px - 16px);
    height: 100%;
    margin: 0 auto; }
  header#main-header-wrapper .left-side, header#main-header-wrapper .right-side {
    position: relative;
    z-index: 1;
    margin: 10px 0;
    padding: 10px; }
  header#main-header-wrapper .left-side .menu-trigger, header#main-header-wrapper .left-side h1 {
    display: inline-block; }
  header#main-header-wrapper .left-side .menu-trigger {
    position: relative;
    top: 4px;
    margin-right: 10px;
    float: left; }
  header#main-header-wrapper .right-side {
    text-align: right;
    float: right; }
    header#main-header-wrapper .right-side .float-right {
      top: 2px;
      position: relative; }
    header#main-header-wrapper .right-side .avatar {
      margin: 0 15px 0 10px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      top: -6px;
      left: -50px;
      position: absolute; }
    header#main-header-wrapper .right-side .grain {
      padding-left: 15px;
      display: inline-block;
      height: 25px; }
    header#main-header-wrapper .right-side .loggedin-nav.float-right {
      top: 0; }

.nav-up {
  top: -102px !important; }

.md-open-menu-container.md-active {
  z-index: 110; }

#profile-navigation h4 {
  padding: 0 15px; }

#main-navigation {
  width: 300px;
  background-color: #FFFFFF;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 2px;
  -webkit-transition: all 0.4s ease-in-out;
  -khtml-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }
  #main-navigation .main-navigation-top {
    margin: 0 40px;
    padding: 40px 0 15px; }
  #main-navigation nav ul {
    margin: 10px 40px 50px; }
    #main-navigation nav ul li {
      color: #555555;
      padding: 8px 0; }
      #main-navigation nav ul li .material-icons {
        top: 4px;
        margin-right: 12px;
        font-size: 22px; }

.md-whiteframe-4dp, .md-whiteframe-z2 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 2px; }

footer {
  display: inline-block;
  width: 100%;
  font-family: "pg-book", sans-serif; }
  footer .copyright {
    width: 100%;
    padding: 20px 0;
    font-size: 13px;
    text-align: center;
    color: #999999; }

.absolute {
  position: absolute;
  bottom: 0;
  width: 100%; }

nav#main-nav {
  height: calc(100vh - 184px);
  position: relative;
  overflow: auto;
  padding-bottom: 50px; }

#main-header .loggedin-nav {
  top: 16px; }
  #main-header .loggedin-nav .signup {
    padding: 8px 12px;
    margin: 0 15px 0 10px;
    font-size: .8em;
    background-color: #EFEFEF;
    border-radius: 2px;
    position: relative;
    top: -6px; }
    #main-header .loggedin-nav .signup:hover {
      color: inherit;
      background-color: #F9F9F9; }

nav#logout-btn {
  position: fixed;
  display: block;
  width: 100%;
  bottom: 0;
  border-top: 1px solid #EFEFEF;
  border-bottom: 0 !important;
  background-color: #FFFFFF; }
  nav#logout-btn ul {
    margin-bottom: 10px; }

.header-title {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  text-indent: -9999px;
  -webkit-transition: all 0.5s ease-in-out;
  -khtml-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: absolute;
  top: 16px;
  width: 200px;
  left: calc(50% - 100px);
  margin: 0 auto;
  text-align: center;
  z-index: 10;
  letter-spacing: -1px;
  background-size: contain; }

/* ==========================================================================
  Modals
========================================================================== */
#modal-form {
  width: 420px;
  padding: 30px 50px 50px;
  margin: 0 auto; }
  #modal-form h2 {
    margin: 20px 0;
    text-align: center;
    font-size: 20px; }
  #modal-form form {
    margin: 20px 0; }
    #modal-form form select {
      margin: 10px auto; }
    #modal-form form p {
      margin-bottom: 5px; }
    #modal-form form input, #modal-form form textarea, #modal-form form select {
      display: block;
      width: 100%;
      max-width: 250px;
      margin: 10px auto;
      border: 1px solid #EFEFEF;
      border-radius: 2px;
      padding: 8px 10px 6px; }
    #modal-form form .center {
      margin: 0; }

/* ==========================================================================
  Login page
========================================================================== */
#login-page {
  height: 100vh; }
  #login-page .left-panel, #login-page .right-panel {
    float: left;
    height: 100vh; }
  #login-page .left-panel {
    width: 34%;
    background-color: #FAFAFA;
    position: relative;
    overflow-y: auto; }
    #login-page .left-panel .logo {
      margin: 0 10%;
      top: 50px; }
    #login-page .left-panel .login-form {
      position: relative;
      top: 45%;
      transform: translateY(-50%);
      max-width: 100%;
      top: 40%;
      padding: 0 10%; }
      #login-page .left-panel .login-form h2 {
        margin-bottom: 30px; }
      #login-page .left-panel .login-form form {
        max-width: 350px;
        margin-top: 20px; }
        #login-page .left-panel .login-form form input {
          margin-bottom: 20px; }
        #login-page .left-panel .login-form form .email {
          float: left;
          width: calc(50% - 14px); }
        #login-page .left-panel .login-form form span.email {
          padding: 9px 0 5px; }
        #login-page .left-panel .login-form form span.at {
          float: left;
          width: 20px;
          padding: 9px 0 5px;
          margin: 0 0 0 5px; }
        #login-page .left-panel .login-form form .team {
          text-transform: lowercase; }
        #login-page .left-panel .login-form form button {
          margin-left: 0; }
        #login-page .left-panel .login-form form .error {
          position: absolute;
          bottom: -60px; }
    #login-page .left-panel .invite {
      margin: 30px 0; }
  #login-page .right-panel {
    width: 66%;
    height: 100vh;
    background: url("https://protein-global-assets.s3.amazonaws.com/protein-os/happiness.jpg") no-repeat;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
    #login-page .right-panel .overlay {
      width: 100%;
      height: 100%;
      background-color: rgba(32, 32, 32, 0.1); }

/* ==========================================================================
  Alerts & Notifications                                          
========================================================================== */
.badge {
  display: inline-block;
  padding: 0.3em;
  min-width: 2.1em;
  font-size: 0.6rem;
  text-align: center;
  border-radius: 50%;
  background: #3c86c1;
  color: #FFFFFF; }

.alert-badge {
  background-color: red; }

.add-to-favourite {
  display: block;
  position: relative;
  top: 20px; }
  .add-to-favourite i {
    font-size: 28px; }

/* ==========================================================================
  Lists                                          
========================================================================== */
ul {
  color: #666666; }
  ul li {
    list-style: none;
    line-height: 1.5; }

ol {
  padding: 0;
  color: #666666; }
  ol li {
    line-height: 1.5; }

/* ==========================================================================
  Links & Buttons                                          
========================================================================== */
a {
  text-decoration: none;
  color: inherit;
  -webkit-transition: all 0.3s ease-in-out;
  -khtml-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  a:hover {
    opacity: .9; }

.underlined-link {
  background-image: linear-gradient(to top, transparent 11%, rgba(0, 0, 0, 0.3) 11%, rgba(0, 0, 0, 0.3) 16%, transparent 16%); }

.green-link {
  color: #14ae7d; }
  .green-link:hover {
    color: rgba(20, 174, 125, 0.8); }

.grey-link, .grey-text {
  color: #999999; }

.grey-link:hover {
  color: #888888; }

.light-grey-text {
  color: #CCCCCC; }

.link .material-icons, .link .zmdi {
  font-size: inherit;
  position: relative;
  top: 3px;
  margin: 5px;
  color: inherit; }

.no-link {
  pointer-events: none;
  cursor: default; }
  .no-link:hover {
    background-color: transparent; }

.material-icons.with-margin {
  margin: 0px 5px;
  top: 4px; }

button, .btn {
  position: relative;
  display: block;
  width: 220px;
  max-width: 300px;
  padding: 10px 18px;
  margin: 20px 5px;
  border: 1px solid transparent;
  border-radius: 3px;
  background-color: #EFEFEF;
  font-family: "HelveticaNeueLight", sans-serif;
  font-size: 14px;
  color: #555555;
  text-align: center;
  line-height: 1.5;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -khtml-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  button:hover, .btn:hover {
    background-color: #DDDDDD; }

.btn .material-icons, .btn .zmdi, button .material-icons, button .zmdi, .alt-btn .material-icons, .alt-btn .zmdi {
  position: relative;
  top: 3px;
  font-size: 16px;
  padding: 0;
  margin: 0 3px;
  color: inherit; }

.center-btn {
  margin: 20px auto; }

.inline-btn, .inline-text {
  display: inline-block; }

.border-btn {
  background-color: transparent;
  border: 1px solid #EFEFEF; }

.coloured-btn {
  background-color: rgba(20, 174, 125, 0.8);
  color: #FFFFFF; }
  .coloured-btn:hover {
    background-color: rgba(20, 174, 125, 0.6); }

.transparent-btn {
  background-color: transparent; }

.wide-btn {
  max-width: 450px;
  width: 100%;
  font-size: 1.1em; }

.submit-btn {
  color: #FFFFFF;
  background-color: rgba(60, 134, 193, 0.8); }
  .submit-btn:hover {
    background-color: rgba(60, 134, 193, 0.6); }

.disabled-btn {
  cursor: default;
  background-color: #F9F9F9; }
  .disabled-btn:hover {
    background-color: #F9F9F9; }

.warning-btn, .cancel-btn {
  color: #FFFFFF;
  background-color: rgba(255, 50, 50, 0.6); }
  .warning-btn:hover, .cancel-btn:hover {
    background-color: rgba(255, 50, 50, 0.5); }

.small-btn {
  width: 120px;
  padding: 7px 7px; }

.large-btn {
  font-size: 1em;
  width: 260px; }

.warning-text {
  color: rgba(255, 50, 50, 0.8) !important; }

a .at-icon-wrapper {
  display: none; }

.edit-options md-fab-trigger .md-button.md-fab {
  width: 50px;
  height: 50px;
  box-shadow: none;
  background-color: #EFEFEF !important; }
  .edit-options md-fab-trigger .md-button.md-fab:hover {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    border-radius: 2px;
    border-radius: 50%; }

.edit-options .material-icons {
  display: block;
  color: #555555;
  font-size: 26px;
  top: 1px;
  margin: 0 auto;
  -webkit-transition: all 0.5s ease-in-out;
  -khtml-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }
  .edit-options .material-icons:hover {
    color: rgba(85, 85, 85, 0.8); }

.edit-options .push-down {
  top: 11px; }

/* ==========================================================================
  Images                                        
========================================================================== */
.background-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

.circle-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #EFEFEF; }

.avatar {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

/* ==========================================================================
  Index styles                                          
========================================================================== */
div[role="main"] {
  overflow: hidden; }

.main {
  padding-top: 60px;
  min-height: calc(100vh - 60px); }

.wrapper-lg {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2em 0;
  position: relative; }

.wrapper-md {
  max-width: 960px;
  margin: 0 auto;
  padding: 2em 0;
  position: relative; }

.wrapper-sm {
  max-width: 720px;
  margin: 0 auto;
  padding: 1.5em 0;
  position: relative; }

.with-padding {
  padding-right: 2%;
  padding-left: 2%; }

.layout-margin {
  margin: 8px 12px; }

.modal {
  padding: 30px 50px;
  max-width: 480px; }

section {
  margin-bottom: 50px;
  position: relative; }

.page-hero {
  margin-bottom: 30px;
  padding: 2em 1%; }
  .page-hero .columns {
    margin-top: 0;
    margin-bottom: 0; }
  .page-hero h1 {
    margin-bottom: 0; }
  .page-hero .image-card {
    height: 380px; }
    .page-hero .image-card .text {
      max-width: 80%; }

.card {
  position: relative;
  background-color: #FFFFFF;
  font-size: 14px;
  border-radius: 3px;
  -webkit-transition: all 0.3s ease-in-out;
  -khtml-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .card:hover {
    box-shadow: 0 0 rgba(0, 0, 0, 0.14), 0 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box; }
    .card:hover .image {
      -webkit-filter: grayscale(0%); }
  .card .image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-filter: grayscale(70%);
    -webkit-transition: all 0.3s ease-in-out;
    -khtml-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .card .text p {
    line-height: 1.5; }

.border-card {
  padding: 1em 1em;
  border: 1px solid #EFEFEF; }

.colour-card {
  background-color: #f9f9f9;
  min-height: 235px;
  padding: 3em 2.4em; }
  .colour-card .text h2 {
    font-size: 24px;
    margin: 0 0 0.5em; }
  .colour-card .text p, .colour-card .text .link {
    font-size: 16px; }
  .colour-card .text h2 {
    max-width: 90%;
    font-size: 26px; }
  .colour-card .text h3 {
    font-size: 22px; }

.plain-card {
  border: 1px solid #EFEFEF;
  background-color: #FFFFFF;
  padding: 3em 2.4em; }

.subtle-card {
  background-color: #FAFAFA;
  padding: 3em 2.4em; }

a.colour-card:hover {
  color: #FFFFFF; }

.banner-card {
  padding: 20px 30px;
  margin: 30px;
  height: auto;
  position: absolute;
  bottom: 0; }

.fixed-height {
  height: 265px; }

.large {
  font-size: 2em; }

.article-card {
  padding: 0;
  height: 320px; }
  .article-card .image {
    height: 45%; }
  .article-card .text-wrapper {
    height: 360px;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d; }
  .article-card .text {
    text-align: center;
    padding: 1.2em 2em; }

.image-card {
  padding: 0;
  height: 340px;
  color: #FFFFFF; }
  .image-card .image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-transition: 3px;
    -khtml-transition: 3px;
    -moz-transition: 3px;
    -ms-transition: 3px;
    -o-transition: 3px;
    border-radius: 3px; }
    .image-card .image:after {
      content: " ";
      display: block;
      width: 100%;
      height: 100%;
      background: -webkit-linear-gradient(transparent, #202020);
      background: -o-linear-gradient(transparent, #202020);
      background: -moz-linear-gradient(transparent, #202020);
      background: linear-gradient(transparent, #202020);
      -webkit-transition: 3px;
      -khtml-transition: 3px;
      -moz-transition: 3px;
      -ms-transition: 3px;
      -o-transition: 3px;
      border-radius: 3px; }
  .image-card .text {
    position: absolute;
    bottom: 10px;
    padding: 1.2em 2em; }
    .image-card .text h2, .image-card .text p, .image-card .text .source {
      color: #FFFFFF; }

.jumbo-card {
  padding: 0;
  height: 450px; }
  .jumbo-card .image {
    background-position: 50% 30%; }
  .jumbo-card .text-wrapper {
    height: 200px;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d; }
  .jumbo-card .text h2 {
    font-size: 24px;
    margin: 0 0 0.5em; }
  .jumbo-card .text p, .jumbo-card .text .link {
    font-size: 16px; }
  .jumbo-card .text {
    padding: 1.2em 2em;
    max-width: 90%; }

.list-card {
  display: block;
  margin: 20px 0;
  background-color: transparent; }
  .list-card .image-col {
    position: relative; }
    .list-card .image-col:after {
      content: ' ';
      height: 90%;
      width: 2px;
      background-color: #EFEFEF;
      display: inline-block;
      position: absolute;
      top: 5%;
      right: 0; }
  .list-card .image {
    height: 140px;
    margin: 5px 25px 5px; }
  .list-card .text {
    margin: 10px 20px 10px 0; }
  .list-card .text {
    padding-left: 20px; }
  .list-card:hover {
    box-shadow: none; }

/* ==========================================================================
  Page headers                                          
========================================================================== */
.main-header {
  margin: 10px 0;
  float: left;
  width: 100%; }

.filters {
  position: relative;
  top: 20px; }

/* ==========================================================================
  Article styles                                          
========================================================================== */
article {
  padding-bottom: 50px; }
  article .text h2, article .text p {
    color: #555555; }

.article-hero {
  position: relative;
  width: 100%;
  height: 520px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

.article-info h1 {
  font-size: 2.2em; }

.article-info h3 {
  margin-bottom: 20px; }

.article-content {
  font-size: 19px; }
  .article-content .article-section:not(:first-of-type) {
    margin-top: 80px; }
  .article-content .article-image {
    margin: 50px auto;
    text-align: center; }
    .article-content .article-image .image {
      position: relative; }
    .article-content .article-image img {
      max-height: 620px;
      margin: 0 auto;
      position: relative;
      z-index: 10; }
    .article-content .article-image .caption {
      color: #CCCCCC;
      font-size: 0.7em;
      margin-top: 10px;
      line-height: 1.4; }
  .article-content .article-text {
    z-index: 10; }
  .article-content .article-block {
    margin-bottom: 80px; }
  .article-content .article-subheader {
    margin-bottom: 20px; }
  .article-content .blockquote {
    margin: 20px auto;
    position: relative;
    font-size: 1.8em;
    max-width: 920px; }
    .article-content .blockquote:before {
      content: '';
      display: block;
      height: 150%;
      width: 250%;
      background-color: rgba(255, 253, 240, 0.7);
      position: absolute;
      left: -70%;
      top: -15%;
      transform: rotate(1deg);
      overflow: hidden; }
    .article-content .blockquote blockquote {
      font-size: 1.5em;
      font-family: "HelveticaNeueMedium", sans-serif; }
    .article-content .blockquote h3 {
      padding-left: 20px;
      position: relative; }
      .article-content .blockquote h3:before, .article-content .blockquote h3:after {
        position: absolute;
        font-size: 48px; }
      .article-content .blockquote h3:before {
        content: "\201c";
        top: -10px;
        left: -5px; }
      .article-content .blockquote h3:after {
        content: "\201d";
        bottom: -15px;
        margin-left: 8px; }
    .article-content .blockquote h4 {
      padding-left: 50px;
      font-size: 17px;
      color: #999999; }
      .article-content .blockquote h4:before {
        content: " ";
        width: 20px;
        height: 1px;
        position: relative;
        display: block;
        top: 9px;
        left: -25px;
        background-color: #CCCCCC; }
    .article-content .blockquote .image-download {
      bottom: 0px;
      color: #CCCCCC; }
  .article-content .list-block ul {
    padding-left: 20px; }
    .article-content .list-block ul li {
      list-style: outside;
      margin-bottom: 15px;
      padding-left: 5px; }

.image-download {
  position: absolute;
  bottom: 15px;
  right: 15px;
  cursor: pointer;
  color: #FFFFFF; }

.tags .tag {
  display: inline-block;
  margin-right: 10px;
  color: #CCCCCC; }
  .tags .tag .material-icons {
    color: #DDDDDD;
    font-size: 0.9em;
    margin-right: 2px;
    top: 2px;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    -khtml-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .tags .tag a:hover .material-icons {
    color: #999999; }

#article-contents-navigation {
  position: absolute;
  top: 70px;
  width: 200px; }

.count-block {
  padding-left: 20px;
  counter-reset: ol;
  color: #777777; }
  .count-block ol > li {
    position: relative;
    margin: 0 0 1.4em 25px;
    padding-left: 15px; }
    .count-block ol > li:before {
      content: "0" counter(ol);
      counter-increment: ol;
      position: absolute;
      top: 0;
      left: -25px;
      margin-right: 8px;
      padding: 4px;
      width: 16px;
      height: 16px;
      line-height: 14px;
      font-size: 1em;
      background-color: #FFF;
      text-align: center;
      z-index: 5; }
  .count-block ol:nth-of-type(n+10) > li:before, .count-block ol li:nth-child(n+10):before {
    content: counter(ol); }
  .count-block p {
    padding-left: 5px;
    margin: 0.2em 0 0.6em;
    line-height: 1.2; }
  .count-block ul {
    padding-left: 5px; }
    .count-block ul li {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 1.2; }

#article-sector-navigation {
  position: absolute;
  top: 50%;
  right: 0;
  width: 200px; }

.trigger {
  padding: 0 20px; }

.connected-block {
  position: relative;
  margin: 20px 0;
  padding: 0 20px 0 30px;
  font-size: 15px;
  color: #777777; }
  .connected-block:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    left: 15px;
    top: 10px;
    bottom: 0;
    border: 1px solid #EFEFEF; }
  .connected-block li {
    margin-bottom: 25px;
    position: relative;
    font-size: 14px;
    line-height: 1.2; }
    .connected-block li:before {
      content: "lens";
      font-family: "Material Icons";
      color: #EFEFEF;
      position: absolute;
      top: 2px;
      left: -25px;
      font-size: 12px;
      z-index: 2;
      -webkit-transition: all 0.3s ease-in-out;
      -khtml-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .connected-block li:hover:before {
      color: #999999; }

.active {
  height: auto; }

#article-related-navigation {
  margin-bottom: 30px; }
  #article-related-navigation .related-block {
    background-color: #F9F9F9;
    padding: 50px 50px;
    height: 315px;
    -webkit-transition: all 0.5s ease-in-out;
    -khtml-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out; }
    #article-related-navigation .related-block:hover {
      background-color: #EFEFEF; }
    #article-related-navigation .related-block:first-of-type {
      border-right: 1px solid #EFEFEF; }

#tag-block {
  text-align: center;
  position: relative; }
  #tag-block h2 {
    color: #555555; }
  #tag-block li {
    display: inline-block; }
    #tag-block li a:after {
      content: '/';
      margin: 0 5px; }
    #tag-block li:last-of-type a:after {
      content: ''; }

/* ==========================================================================
  Forms                                          
========================================================================== */
form {
  position: relative;
  color: #555555;
  margin: 30px 0; }
  form .form-row {
    margin-bottom: 30px; }
  form input {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    border: 1px solid #EFEFEF;
    border-radius: 2px;
    padding: 8px 10px 6px;
    color: #555555; }
  form textarea {
    width: 100%;
    padding: 15px 10px;
    color: #999999;
    font-size: 14px;
    line-height: 1.5;
    border: 1px solid #EFEFEF; }
  form select {
    margin: 0 10px 5px 0; }

label {
  font-size: 0.8em;
  color: #999999;
  padding-left: 1px;
  display: block;
  margin-bottom: 5px; }

.field {
  -webkit-transition: all 500ms ease-in-out;
  -khtml-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  -webkit-transition: 2px;
  -khtml-transition: 2px;
  -moz-transition: 2px;
  -ms-transition: 2px;
  -o-transition: 2px;
  border-radius: 2px;
  position: relative;
  border: 1px solid #EFEFEF;
  z-index: 0;
  color: #999999; }

.field-sm .field {
  width: 100%;
  margin: 3px 0 20px;
  padding: 10px 5px;
  font-size: 1em; }

.field-lg .field {
  width: 100%;
  margin: 20px 0 5px;
  padding: 15px;
  font-size: 1.1em; }

.field-lg .search-icon {
  position: absolute;
  left: 20px;
  top: 15px;
  color: #CCCCCC;
  z-index: 5; }

.field-lg .field-with-icon {
  text-indent: 45px; }

.profile-form-image .circle-image {
  margin: 0 auto 10px; }

.profile-form-image .image-warning {
  width: 160px;
  margin: 10px auto;
  font-size: 14px;
  text-align: center;
  line-height: 1.4; }

.profile-form-info {
  margin-bottom: 30px; }
  .profile-form-info h1 {
    margin-bottom: 20px; }
  .profile-form-info li {
    display: inline-block;
    font-size: 0.9em;
    margin-right: 3px; }

md-tabs-wrapper {
  height: 60px; }
  md-tabs-wrapper md-tabs-canvas {
    height: 45px; }
    md-tabs-wrapper md-tabs-canvas md-pagination-wrapper {
      margin: 0 auto;
      position: relative; }
      md-tabs-wrapper md-tabs-canvas md-pagination-wrapper .md-tab {
        font-family: "HelveticaNeueLight", sans-serif;
        padding: 2px;
        border-radius: 0;
        line-height: 1;
        color: #555555 !important;
        font-size: 17px;
        box-sizing: border-box;
        text-transform: none;
        margin: 10px 20px; }

.layout-margin > .flex {
  margin: 8px 20px !important; }

md-pagination-wrapper .md-active {
  border-bottom: 2px solid #CCCCCC; }

.interest-grid-block, .ideas-grid-block {
  float: left;
  position: relative;
  cursor: pointer;
  border-radius: 2px;
  background-color: #FAFAFA;
  box-shadow: 0 0 rgba(0, 0, 0, 0.14), 0 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease-in-out;
  -khtml-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .interest-grid-block:hover, .ideas-grid-block:hover {
    box-shadow: 0; }

.active-block {
  background-color: #EFEFEF;
  box-shadow: 0; }

.interest-grid {
  width: 580px;
  margin: 0 auto 60px; }
  .interest-grid .interest-grid-block {
    width: 115px;
    height: 115px;
    display: inline-block;
    margin: 10px 15px;
    padding: 22px 8px;
    text-align: center; }
    .interest-grid .interest-grid-block h3 {
      font-size: 14px;
      margin: 5px 0; }

.ideas-grid {
  width: 80%;
  margin: 0 auto 60px; }
  .ideas-grid .ideas-grid-block {
    width: 100%;
    height: auto;
    display: block;
    margin: 5px auto;
    padding: 10px 30px; }
    .ideas-grid .ideas-grid-block h3 {
      font-size: 16px;
      padding: 5px 0;
      float: left; }
    .ideas-grid .ideas-grid-block i.left {
      float: left;
      position: relative;
      top: 10px;
      margin-right: 15px; }
    .ideas-grid .ideas-grid-block .marked {
      position: absolute;
      top: 20px;
      right: 20px;
      color: transparent; }
  .ideas-grid .active-block .marked {
    color: #14ae7d; }

#search-form {
  margin: 10px 0; }

#search-page {
  min-height: 0; }
  #search-page #search-form {
    margin: 0; }

.header-title {
  background-image: url("https://protein-global-assets.s3.amazonaws.com/protein-radar/clients/sonos/sonos-logo.svg");
  top: 25px;
  height: 20px; }

#menu-wrapper .main-logo {
  background-image: url("https://protein-global-assets.s3.amazonaws.com/protein-monitor/protein_monitor_white.svg"); }

#menu-wrapper .menu-slide-left {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("https://protein-global-assets.s3.amazonaws.com/protein-radar/clients/sonos/sonos-cover.jpg"); }
  #menu-wrapper .menu-slide-left .text-block {
    display: none; }

#menu-wrapper .menu-slide-right-wrap #main-nav ul li span, #menu-wrapper .menu-slide-right-wrap #main-nav ul li a {
  color: rgba(255, 255, 255, 0.9); }

#menu-wrapper .menu-slide-right-wrap #main-nav ul li.active span, #menu-wrapper .menu-slide-right-wrap #main-nav ul li.active a {
  color: #FF0043; }

/* ==========================================================================
	Custom                                          
========================================================================== */
.protein-logo-black {
  background-image: url("https://protein-global-assets.s3.amazonaws.com/protein/logos/protein_reports_black.svg");
  width: 250px;
  height: 45px;
  background-size: contain; }

.protein-logo-white {
  background-image: url("http://protein-global-assets.s3.amazonaws.com/protein-monitor/protein_monitor_white.svg"); }

.loading {
  background-color: #F5F2F6; }

.loading-animation svg path, .loading-animation svg rect {
  fill: #FF0043; }

body.no-scroll {
  overflow-y: hidden; }

.coloured-text, .connected-block li:hover:before, .quote-mark strong {
  color: #FF0043; }

.coloured-underline {
  border-bottom: 2px solid rgba(255, 0, 67, 0.7);
  display: inline-block; }

.coloured-link, .coloured-link i, nav ul li.active a, nav ul li.active span, .article-text a, ul.sub_section .active {
  color: #FF0043; }
  .coloured-link:hover, .coloured-link i:hover, nav ul li.active a:hover, nav ul li.active span:hover, .article-text a:hover, ul.sub_section .active:hover {
    color: rgba(255, 0, 67, 0.7); }

.coloured-btn {
  background-color: #ff0043; }
  .coloured-btn:hover {
    background-color: rgba(255, 0, 67, 0.8); }

hr.coloured {
  background-color: #ff0043; }

.coloured-background, .list-card .image-col:after {
  background-color: #ff0043; }

.colour-card {
  background-color: #ff0043;
  min-height: 260px; }
  .colour-card h1, .colour-card h2, .colour-card h3, .colour-card h4, .colour-card p, .colour-card span {
    color: #FFFFFF; }
  .colour-card .text {
    color: #FFFFFF; }
    .colour-card .text h1, .colour-card .text h2, .colour-card .text h3, .colour-card .text h4, .colour-card .text p, .colour-card .text span {
      color: #FFFFFF; }
    .colour-card .text h2.super-size {
      font-size: 32px;
      margin-bottom: 0.4em; }
  .colour-card .alt-btn span {
    color: #FF0043; }

::selection {
  background-color: #ff0043;
  color: #FFFFFF; }

.field-lg .field {
  background-color: #FFF; }

#article-related-navigation .related-block {
  background-color: rgba(255, 0, 67, 0.05); }
  #article-related-navigation .related-block:hover {
    background-color: rgba(255, 0, 67, 0.2); }
  #article-related-navigation .related-block:first-of-type {
    border-right: 1px solid rgba(255, 0, 67, 0.1); }

#search-form {
  margin: 0 0 20px 0; }
  #search-form .field:active, #search-form .field:focus {
    box-shadow: 0 0 3px 0 rgba(255, 0, 67, 0.5);
    border-color: rgba(255, 0, 67, 0.5); }

.underline, md-pagination-wrapper .md-active, .border-card {
  border-color: #FF0043; }

.purple {
  background-color: #8e55a2;
  min-height: 230px; }
  .purple .alt-btn {
    color: #8e55a2;
    border-color: #8e55a2;
    box-shadow: 1px 1px 5px rgba(142, 85, 162, 0.5); }
    .purple .alt-btn span {
      color: #8e55a2; }

.add-item-btn {
  position: fixed;
  bottom: 20px;
  right: 20px; }
  .add-item-btn .md-button {
    background-color: #FF0043 !important; }
    .add-item-btn .md-button .material-icons {
      top: 0;
      font-size: 24px;
      margin: 0;
      color: #FFFFFF; }

header {
  background-color: rgba(255, 255, 255, 0); }

.nav-up {
  top: -102px !important; }

.nav-down {
  background-color: white;
  box-shadow: 0 0 rgba(0, 0, 0, 0.14), 0 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box; }

.main {
  padding-top: 80px;
  padding-bottom: 50px;
  min-height: calc(100vh - 80px); }

.page-hero-slim {
  padding: 2em 1% 0; }

.alt-btn {
  border-radius: 50px;
  width: auto;
  font-size: 16px;
  border: 1px solid #FF0043;
  color: #FF0043;
  background-color: #FFFFFF;
  box-shadow: 1px 1px 5px rgba(255, 0, 67, 0.5);
  text-align: center;
  display: inline-block;
  padding: 0.6em 1em 0.7em; }
  .alt-btn:hover {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(255, 0, 67, 0.5); }

/* ==========================================================================
	Sales page                                          
========================================================================== */
@-webkit-keyframes Floatingx {
  from {
    -webkit-transform: translate(0, 0px); }
  65% {
    -webkit-transform: translate(0, 15px); }
  to {
    -webkit-transform: translate(0, 0px); } }

@-moz-keyframes Floating {
  from {
    -moz-transform: translate(0, 0px); }
  65% {
    -moz-transform: translate(0, 15px); }
  to {
    -moz-transform: translate(0, 0px); } }

@-webkit-keyframes scalex {
  from {
    -webkit-transform: scale(0.75); }
  65% {
    -webkit-transform: scale(1); }
  to {
    -webkit-transform: scale(0.75); } }

@-moz-keyframes scale {
  from {
    -moz-transform: scale(0.9); }
  65% {
    -moz-transform: scale(3); }
  to {
    -moz-transform: scale(0.9); } }

#modal-form {
  width: 520px;
  padding: 50px 80px 30px;
  margin: 0 auto; }
  #modal-form form {
    max-width: 320px;
    margin: 0 auto; }
    #modal-form form input, #modal-form form textarea {
      width: 100%;
      max-width: 320px; }
    #modal-form form .agile-group {
      margin-bottom: 15px; }
    #modal-form form button {
      margin: 40px auto 0; }

#home-page button, #home-page .btn {
  border-radius: 50px;
  width: auto;
  max-width: 140px;
  font-size: 16px;
  border: 1px solid #FF0043;
  color: #FF0043;
  background-color: #FFFFFF;
  box-shadow: 1px 1px 5px rgba(255, 0, 67, 0.5); }
  #home-page button:hover, #home-page .btn:hover {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(255, 0, 67, 0.5); }

#home-page header#main-header-wrapper {
  position: absolute !important;
  height: 80px;
  background-color: #14ae7d;
  box-shadow: none;
  top: 0 !important; }
  #home-page header#main-header-wrapper .material-icons {
    color: #FFFFFF; }
  #home-page header#main-header-wrapper .header-title {
    background-image: url("https://protein-global-assets.s3.amazonaws.com/protein-radar/protein_radar_white.svg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    text-indent: -9999px;
    top: 22px;
    max-height: 25px; }

#home-page .main {
  padding-bottom: 0; }

#home-page section {
  padding: 60px 0 70px;
  margin: 0 auto;
  position: relative; }
  #home-page section p, #home-page section h3 {
    font-size: 1.1em;
    margin-bottom: 1em; }
  #home-page section .steps {
    margin: 10px auto;
    max-width: 520px;
    padding: 40px 60px;
    font-size: 14px; }
    #home-page section .steps h1 {
      margin-top: 0; }
    #home-page section .steps ul {
      padding-left: 50px; }
      #home-page section .steps ul li {
        position: relative;
        margin: 10px auto 30px; }
      #home-page section .steps ul .count {
        position: absolute;
        left: -50px;
        top: 0px;
        font-size: 16px;
        border: 1px solid #FF0043;
        border-radius: 50%;
        padding: 6px 9px;
        color: #FF0043;
        line-height: 1;
        font-family: "HelveticaNeueMedium", sans-serif; }
  #home-page section #features .border-box {
    min-height: 436px; }

#home-page .background-shape {
  z-index: -1px; }

#home-page .page-hero {
  padding: 30px 0;
  background-color: #FF0043; }
  #home-page .page-hero h1, #home-page .page-hero h2, #home-page .page-hero p {
    color: #FFFFFF; }
  #home-page .page-hero .text-block {
    max-width: 720px;
    margin: 0 auto; }
    #home-page .page-hero .text-block p {
      padding: 0 50px; }

#browser {
  background: url("https://protein-global-assets.s3.amazonaws.com/protein-os/browser.png") no-repeat center center;
  height: 746px;
  position: absolute;
  width: 1480px;
  left: calc(50% - 743px);
  background-size: contain; }

.promo-video {
  width: 996px;
  height: 493px;
  margin-top: 30px; }
  .promo-video video {
    position: relative;
    width: 878px;
    top: 63px;
    left: 5px;
    margin: 0 auto;
    display: block; }
  @media only screen and (min-width: 0px) and (max-width: 859px), (device-aspect-ratio: 40 / 71) {
    .promo-video {
      display: none; } }

#agile-form.email-form {
  display: inline-block;
  max-width: 415px; }
  #agile-form.email-form .agile-group {
    float: left; }
  #agile-form.email-form input, #agile-form.email-form button {
    border-radius: 2px;
    border: 0; }
  #agile-form.email-form input {
    padding: 10px;
    width: 300px; }
  #agile-form.email-form button {
    margin: 0 5px;
    padding: 8px 12px; }

.border-box {
  border-top: 10px solid #FF0043;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  background-color: white;
  padding: 40px 40px; }

.special-font {
  font-size: 1.6em;
  width: 100px;
  float: right;
  margin-top: 5px; }

.decoration {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0; }
  .decoration .shape {
    width: 50px;
    height: 60px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: .1;
    position: absolute; }
  .decoration .circle {
    background-image: url("../assets/images/shapes/circle.svg"); }
  .decoration .square {
    background-image: url("../assets/images/shapes/square.svg"); }
  .decoration .pentagon {
    background-image: url("../assets/images/shapes/pentagon.svg"); }
  .decoration .semicircle {
    background-image: url("../assets/images/shapes/semicircle.svg"); }
  .decoration .triangle {
    background-image: url("../assets/images/shapes/triangle.svg"); }
  .decoration .shape-1 {
    top: 5%;
    left: 30%;
    float: left;
    -webkit-animation-name: Floatingx;
    -webkit-animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-delay: 2s;
    -moz-animation-name: Floating;
    -moz-animation-duration: 3s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: ease-in-out;
    -webkit-animation-delay: 2s;
    margin-left: 30px;
    margin-top: 5px; }
  .decoration .shape-2 {
    top: 5%;
    left: 10%;
    float: left;
    -webkit-animation-name: scalex;
    -webkit-animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-delay: 3s;
    -moz-animation-name: scale;
    -moz-animation-duration: 3s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: ease-in-out;
    -webkit-animation-delay: 3s; }
  .decoration .shape-3 {
    top: 10%;
    left: 55%;
    float: left;
    -webkit-animation-name: Floatingx;
    -webkit-animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-delay: 0s;
    -moz-animation-name: Floating;
    -moz-animation-duration: 3s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: ease-in-out;
    -webkit-animation-delay: 0s;
    margin-left: 30px;
    margin-top: 5px; }
  .decoration .shape-4 {
    top: 0%;
    left: 80%;
    float: left;
    -webkit-animation-name: scalex;
    -webkit-animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-delay: 2s;
    -moz-animation-name: scale;
    -moz-animation-duration: 3s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: ease-in-out;
    -webkit-animation-delay: 2s; }
  .decoration .shape-5 {
    top: 50%;
    left: 60%;
    float: left;
    -webkit-animation-name: scalex;
    -webkit-animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-delay: 3s;
    -moz-animation-name: scale;
    -moz-animation-duration: 3s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: ease-in-out;
    -webkit-animation-delay: 3s; }
  .decoration .shape-6 {
    top: 75%;
    left: 80%;
    float: left;
    -webkit-animation-name: Floatingx;
    -webkit-animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-delay: 3s;
    -moz-animation-name: Floating;
    -moz-animation-duration: 3s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: ease-in-out;
    -webkit-animation-delay: 3s;
    margin-left: 30px;
    margin-top: 5px; }
  .decoration .shape-7 {
    top: 80%;
    left: 30%;
    float: left;
    -webkit-animation-name: scalex;
    -webkit-animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-delay: 1s;
    -moz-animation-name: scale;
    -moz-animation-duration: 3s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: ease-in-out;
    -webkit-animation-delay: 1s; }
  .decoration .shape-8 {
    top: 70%;
    left: 5%;
    float: left;
    -webkit-animation-name: Floatingx;
    -webkit-animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-delay: 0s;
    -moz-animation-name: Floating;
    -moz-animation-duration: 3s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: ease-in-out;
    -webkit-animation-delay: 0s;
    margin-left: 30px;
    margin-top: 5px; }
  .decoration .shape-9 {
    top: 40%;
    left: 90%;
    float: left;
    -webkit-animation-name: scalex;
    -webkit-animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-delay: 2s;
    -moz-animation-name: scale;
    -moz-animation-duration: 3s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: ease-in-out;
    -webkit-animation-delay: 2s; }
  .decoration .shape-10 {
    top: 30%;
    left: 20%;
    float: left;
    -webkit-animation-name: Floatingx;
    -webkit-animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-delay: 1s;
    -moz-animation-name: Floating;
    -moz-animation-duration: 3s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: ease-in-out;
    -webkit-animation-delay: 1s;
    margin-left: 30px;
    margin-top: 5px; }

.white-bg h1, .white-bg h2, .grey-bg h1, .grey-bg h2, .color-bg h1, .color-bg h2 {
  color: #FF0043; }

.white-bg h3, .white-bg p, .grey-bg h3, .grey-bg p, .color-bg h3, .color-bg p {
  color: #999999; }

.color-bg {
  background-color: rgba(255, 0, 67, 0.1); }

.grey-bg {
  background: #F9F9F9; }
  .grey-bg .decoration .shape {
    opacity: .9; }

.white-bg {
  background-color: rgba(255, 255, 255, 0.6); }

.image-bg {
  width: 100%;
  height: 520px;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  .image-bg:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(32, 32, 32, 0.3);
    top: 0; }

#image-1 {
  background-image: url("https://protein-global-assets.s3.amazonaws.com/protein-agency/images/intro6.jpg"); }

#image-2 {
  background-image: url("https://protein-global-assets.s3.amazonaws.com/protein-agency/images/intro3.jpg"); }

.icon {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 80px;
  height: 80px;
  margin: 5px auto 25px;
  text-align: center; }

.icon-1 {
  background-image: url("/assets/images/icons/shape-1.svg"); }

.icon-2 {
  background-image: url("/assets/images/icons/shape-2.svg"); }

.icon-3 {
  background-image: url("/assets/images/icons/shape-3.svg"); }

.icon-4 {
  background-image: url("/assets/images/icons/shape-4.svg"); }

.icon-5 {
  background-image: url("/assets/images/icons/shape-5.svg"); }

.icon-6 {
  background-image: url("/assets/images/icons/shape-6.svg"); }

/* ==========================================================================
	Public styles                                          
========================================================================== */
#slider {
  max-width: 1300px;
  margin: 0 auto; }
  #slider .slick-slider {
    padding: 0;
    margin: 60px auto 70px;
    display: block;
    height: 65vh;
    min-height: 520px;
    max-height: 620px;
    width: 90%;
    max-width: 1200px;
    z-index: 10;
    background-color: #FF0043; }
    #slider .slick-slider .slick-slide {
      position: relative;
      height: 65vh;
      min-height: 520px;
      max-height: 620px;
      width: 100%;
      display: block; }
      #slider .slick-slider .slick-slide .image {
        width: 100%;
        height: 65vh;
        min-height: 520px;
        max-height: 620px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-position: center 20%; }
      #slider .slick-slider .slick-slide .text {
        width: 720px;
        left: calc(50% - 360px);
        bottom: 50px;
        text-align: center; }
        #slider .slick-slider .slick-slide .text h2 {
          font-size: 58px;
          line-height: 1.2;
          margin-bottom: 5px; }
        #slider .slick-slider .slick-slide .text p {
          width: 80%;
          margin: 0 auto; }
  #slider .slick-prev, #slider .slick-next {
    display: inline-block;
    position: absolute;
    top: 45%;
    width: auto;
    z-index: 10;
    font-size: 42px;
    color: #666666; }
    #slider .slick-prev:hover, #slider .slick-next:hover {
      color: #FF0043; }
  #slider .slick-prev {
    left: 0px; }
  #slider .slick-next {
    right: 0px; }

#public-page .main {
  padding: 50px 2% 0; }

#public-page .page-hero {
  padding: 0;
  margin: 60px auto 0;
  display: block;
  height: 65vh;
  min-height: 520px;
  max-height: 620px;
  width: 90%;
  max-width: 1500px;
  z-index: 10; }
  #public-page .page-hero .image-card {
    height: 65vh;
    min-height: 520px;
    max-height: 620px;
    width: 100%;
    display: block; }
    #public-page .page-hero .image-card .image {
      width: 100%;
      background-position: center 20%; }
    #public-page .page-hero .image-card .text {
      width: 70%;
      left: 15%;
      bottom: 50px;
      text-align: center; }
      #public-page .page-hero .image-card .text h2 {
        font-size: 58px;
        line-height: 1.2;
        margin-bottom: 5px; }
      #public-page .page-hero .image-card .text h4 {
        color: #FFFFFF; }
      #public-page .page-hero .image-card .text p {
        width: 80%;
        margin: 0 auto; }

#public-page .card .image {
  -webkit-filter: none; }

#public-page a.card:hover {
  box-shadow: 0 0 rgba(0, 0, 0, 0.14), 0 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box; }

#public-page .image-card .text {
  bottom: 20px; }

#public-page .article-hero {
  background-size: cover !important;
  height: 70vh;
  max-height: 620px;
  width: 90%;
  max-width: 1500px;
  margin: 60px auto 0; }

#public-page .services-banner {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("https://protein-global-assets.s3.amazonaws.com/protein-radar/people.jpg"); }

#public-page .homepage-spot {
  height: 495px;
  background: transparent; }
  #public-page .homepage-spot .text {
    padding: 3em 2.4em;
    position: absolute;
    bottom: 10%;
    left: 0; }

#public-page .join-us, #public-page .sign-up {
  position: relative;
  overflow: hidden; }
  #public-page .join-us:after, #public-page .sign-up:after {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute; }

#public-page .join-us:after {
  content: url("/assets/images/icons/shape-1.svg");
  bottom: -30px;
  right: -30px; }

#public-page .sign-up:after {
  content: url("/assets/images/icons/shape-3.svg");
  top: -40px;
  right: 10px; }

/* ==========================================================================
	Sliding menu                                         
========================================================================== */
#menu-wrapper {
  visibility: hidden; }
  #menu-wrapper .menu-slide {
    position: fixed;
    top: 0;
    width: 50%;
    height: 100%;
    background-color: #FFFDF0;
    padding: 50px;
    z-index: 1000;
    -webkit-transition: all 0.4s ease-in-out;
    -khtml-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out; }
  #menu-wrapper .menu-slide-left {
    left: 0;
    transform: translate(-100%, 0);
    background-color: #FF0043;
    color: #FFFFFF; }
  #menu-wrapper .menu-slide-right {
    right: 0;
    transform: translate(100%, 0);
    background-color: #202020;
    overflow-y: auto; }
  #menu-wrapper .menu-slide-left-wrap {
    position: relative;
    height: 100%; }
    #menu-wrapper .menu-slide-left-wrap .text-block {
      position: absolute;
      bottom: 20%; }
      #menu-wrapper .menu-slide-left-wrap .text-block h1, #menu-wrapper .menu-slide-left-wrap .text-block h2, #menu-wrapper .menu-slide-left-wrap .text-block h3, #menu-wrapper .menu-slide-left-wrap .text-block p {
        color: #FFFFFF; }
  #menu-wrapper .menu-slide-right-wrap .close {
    position: absolute;
    right: 50px;
    font-size: 2em;
    color: #CCCCCC; }
  #menu-wrapper .menu-slide-right-wrap .main-logo {
    background-size: contain;
    background-repeat: no-repeat;
    text-indent: -9999px;
    max-height: 40px; }
  #menu-wrapper .menu-slide-right-wrap .main-navigation-top {
    margin: 0 40px;
    padding: 5vh 0 15px; }
  #menu-wrapper .menu-slide-right-wrap #main-nav {
    height: auto; }
    #menu-wrapper .menu-slide-right-wrap #main-nav ul {
      margin: 10px 40px 50px; }
      #menu-wrapper .menu-slide-right-wrap #main-nav ul li {
        color: #555555;
        padding: 8px 0; }
        #menu-wrapper .menu-slide-right-wrap #main-nav ul li .material-icons {
          top: 4px;
          margin-right: 12px;
          font-size: 22px; }

.reveal-menu {
  visibility: visible !important; }
  .reveal-menu .menu-slide-right {
    right: 0;
    transform: translate(0, 0) !important; }
  .reveal-menu .menu-slide-left {
    left: 0;
    transform: translate(0, 0) !important; }
    .reveal-menu .menu-slide-left:after {
      content: " ";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background-color: rgba(32, 32, 32, 0.1); }

.card.larger-title .text h2 {
  font-size: 28px;
  line-height: 1.2; }

/* ==========================================================================
	Contact styles                                          
========================================================================== */
h1.welcome {
  font-size: 3.4em;
  font-family: "HelveticaNeueMedium", sans-serif;
  margin: 0; }

#contacts-count, #sort-list {
  position: absolute;
  top: 25px; }

.client-suggested {
  margin: 6px 0;
  padding: 0 30px;
  color: rgba(255, 0, 67, 0.7); }

#contacts-count {
  left: 1.5%;
  border: 1px solid rgba(255, 0, 67, 0.5);
  padding: 3px 12px 6px;
  height: 38px;
  -webkit-transition: 2px;
  -khtml-transition: 2px;
  -moz-transition: 2px;
  -ms-transition: 2px;
  -o-transition: 2px;
  border-radius: 2px; }
  #contacts-count span {
    display: block;
    margin: 10px 0;
    font-family: "HelveticaNeueLight", sans-serif;
    font-size: 14px;
    color: #FF0043; }

#sort-list {
  right: 1.5%;
  margin-right: 0; }

.cards-wrapper {
  padding-top: 0; }

.drop-down-menu {
  -webkit-transition: 2px;
  -khtml-transition: 2px;
  -moz-transition: 2px;
  -ms-transition: 2px;
  -o-transition: 2px;
  border-radius: 2px;
  margin: 0 21px 0 0;
  padding: 0 0 10px 0; }
  .drop-down-menu label {
    display: none; }
  .drop-down-menu md-select {
    background-color: #FFF;
    border: 1px solid #EFEFEF;
    font-family: "HelveticaNeueLight", sans-serif;
    font-size: 14px;
    color: #555555;
    padding: 3px 8px 6px;
    -webkit-transition: 2px;
    -khtml-transition: 2px;
    -moz-transition: 2px;
    -ms-transition: 2px;
    -o-transition: 2px;
    border-radius: 2px; }
    .drop-down-menu md-select .md-select-value {
      border-bottom: 0; }
      .drop-down-menu md-select .md-select-value .md-select-icon {
        width: 15px; }
  .drop-down-menu md-select.md-default-theme:not([disabled]):focus, .drop-down-menu md-select:not([disabled]):focus .md-select-value {
    border-color: transparent !important;
    color: #555555; }
  .drop-down-menu:nth-child(5n + 4) {
    margin-right: 0 !important; }

md-select-menu md-option[selected] {
  color: #FF0043; }

.md-chips:not(.md-readonly) .md-chip {
  background: #FFF;
  border: 1px solid #CCCCCC; }

.md-chips:not(.md-readonly) .md-focused {
  background: #FF0043;
  border-color: #222222; }

md-chips .md-chips.md-focused {
  box-shadow: 0 2px #FF0043; }

.disabled {
  pointer-events: none; }
  .disabled .md-text {
    color: #CCCCCC; }

.bug {
  background-color: rgba(255, 0, 67, 0.7);
  color: #FFFFFF;
  padding: 5px 5px;
  border-radius: 2px; }

.grey-bug {
  background-color: rgba(119, 119, 119, 0.7); }

.card .text h2 {
  font-size: 24px;
  margin: 0 0 0.5em; }

.card .text p, .card .text .link {
  font-size: 16px; }

.card:hover {
  box-shadow: none; }

.card .type {
  position: absolute;
  top: 15px;
  right: 10px;
  padding: 5px;
  -webkit-transition: 2px;
  -khtml-transition: 2px;
  -moz-transition: 2px;
  -ms-transition: 2px;
  -o-transition: 2px;
  border-radius: 2px; }
  .card .type li {
    display: inline-block;
    margin-left: 5px;
    vertical-align: top; }
    .card .type li .flag {
      color: rgba(255, 0, 67, 0.7); }

.card .text h2 {
  margin-bottom: 0; }

.card .text p {
  margin-bottom: 1em;
  line-height: 1.4; }

.card .social-icons span {
  margin-right: 5px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px; }

.subtle-card .text h2 {
  margin-bottom: .5em; }

.list-card .image {
  height: 180px; }

.type-insight-report {
  background-color: #f68f4a; }

.type-activation-report {
  background-color: #14ae7d; }

.flip-container {
  perspective: 1000;
  display: inline-block;
  float: left;
  height: 100%;
  width: 100%; }
  .flip-container .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
    height: 100%;
    width: 100%; }
    .flip-container .flipper .front, .flip-container .flipper .back {
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%; }
      .flip-container .flipper .front .flip-back, .flip-container .flipper .back .flip-back {
        cursor: pointer;
        position: absolute;
        background-color: transparent;
        right: 0;
        bottom: 0;
        width: 24px;
        height: 24px;
        z-index: 20; }
      .flip-container .flipper .front:before, .flip-container .flipper .front:after, .flip-container .flipper .back:before, .flip-container .flipper .back:after {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        content: "";
        z-index: 10; }
    .flip-container .flipper .front {
      z-index: 2;
      transform: rotateY(0deg); }
      .flip-container .flipper .front:before {
        border-bottom: solid 12px #FFFFFF;
        border-right: solid 12px #FFFFFF;
        border-left: solid 12px transparent;
        border-top: solid 12px transparent; }
      .flip-container .flipper .front:after {
        border-top: solid 12px rgba(255, 0, 67, 0.5);
        border-left: solid 12px rgba(255, 0, 67, 0.5);
        border-right: solid 12px transparent;
        border-bottom: solid 12px transparent; }
    .flip-container .flipper .back {
      transform: rotateY(180deg);
      background-color: rgba(255, 0, 67, 0.2); }
      .flip-container .flipper .back:before {
        border-bottom: solid 12px #fff;
        border-right: solid 12px #fff;
        border-left: solid 12px transparent;
        border-top: solid 12px transparent; }
      .flip-container .flipper .back:after {
        border-top: solid 12px rgba(255, 0, 67, 0.5);
        border-left: solid 12px rgba(255, 0, 67, 0.5);
        border-right: solid 12px transparent;
        border-bottom: solid 12px transparent; }
      .flip-container .flipper .back .text {
        top: 10px; }
        .flip-container .flipper .back .text h2, .flip-container .flipper .back .text p {
          color: #555555 !important; }
        .flip-container .flipper .back .text h2 {
          font-size: 22px;
          margin-top: 10px; }
        .flip-container .flipper .back .text p {
          margin-top: 20px; }
      .flip-container .flipper .back .material-icons {
        color: #555555 !important; }
    .flip-container .flipper.flip {
      transform: rotateY(-180deg); }
    .flip-container .flipper .add-me {
      position: absolute;
      cursor: pointer;
      z-index: 10;
      top: 15px;
      left: 10px; }
      .flip-container .flipper .add-me .material-icons {
        color: #FFFFFF; }

h1.super-super-size {
  font-size: 5em;
  margin-bottom: 0;
  line-height: 1; }

#article-show .article-hero {
  background-size: contain;
  background-repeat: repeat;
  box-shadow: 0 0 rgba(0, 0, 0, 0.14), 0 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  background-color: #FFFFFF; }

#article-show .article-info h1 {
  font-size: 3.2em; }

#article-show .article-info h3 {
  font-size: 1.4em; }

#article-show .article-content .article-text {
  font-size: 20px; }

#article-show .article-content .article-image img {
  box-shadow: 0 0 rgba(0, 0, 0, 0.14), 0 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  background-color: #FFFFFF; }

article-footer .colour-card, people-footer .colour-card, .campaign-footer .colour-card {
  height: 230px; }
  article-footer .colour-card .text, people-footer .colour-card .text, .campaign-footer .colour-card .text {
    position: relative;
    top: 45%;
    transform: translateY(-50%); }

#people-show #article-head .article-hero {
  position: relative;
  background-size: contain;
  background-repeat: repeat-x;
  padding: 0;
  border-bottom: 12px solid #FF0043; }
  #people-show #article-head .article-hero .skills li {
    font-size: .9em;
    float: left;
    margin-right: 8px;
    line-height: 1; }
  #people-show #article-head .article-hero:after {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(transparent, #202020);
    background: -o-linear-gradient(transparent, #202020);
    background: -moz-linear-gradient(transparent, #202020);
    background: linear-gradient(transparent, #202020); }

#people-show #article-head .article-info {
  position: absolute;
  padding: 30px 10%; }
  #people-show #article-head .article-info h1, #people-show #article-head .article-info h3 {
    color: #FFFFFF; }
  #people-show #article-head .article-info h3 {
    margin-bottom: 5px; }
  #people-show #article-head .article-info .skills {
    margin-top: 20px; }

#people-show #article-main .main-col {
  margin-left: 0;
  padding-left: 1.5%; }
  #people-show #article-main .main-col .text-block {
    margin: 0 2% 50px; }
    #people-show #article-main .main-col .text-block p {
      max-width: 560px; }

#people-show #article-main .aside-col {
  margin-right: 0;
  padding-right: 1.5%; }
  #people-show #article-main .aside-col .card {
    margin: 20px 0; }
    #people-show #article-main .aside-col .card h3 {
      margin-top: 0; }
  #people-show #article-main .aside-col .btn {
    width: auto; }
  #people-show #article-main .aside-col .connected-block {
    padding: 0 0 0 20px; }
    #people-show #article-main .aside-col .connected-block:before {
      left: 5px; }
    #people-show #article-main .aside-col .connected-block li:before {
      left: -20px; }

.contact-tags li {
  float: left;
  margin-right: 3px; }
  .contact-tags li:after {
    content: ", "; }
  .contact-tags li:last-of-type:after, .contact-tags li:first-of-type:after {
    content: ""; }

.total-reach {
  font-size: 2em;
  margin: 0; }

.social_total {
  margin: 30px auto;
  position: relative; }
  .social_total .material-icons {
    font-size: 14px;
    margin: 0 5px;
    position: relative;
    top: 1px; }
  .social_total .right {
    float: right;
    line-height: 1; }

.social_stat_block {
  margin: 5px 0 20px; }

.social_chart {
  height: 12px;
  width: 100%;
  background-color: #EFEFEF;
  margin: 5px auto;
  border-radius: 50px; }
  .social_chart .social_chart-count {
    height: 100%;
    display: inline-block;
    float: left;
    opacity: 0.8;
    -webkit-transition: all 0.3s ease-in-out;
    -khtml-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border-right: 0.5px solid #F9F9F9; }
    .social_chart .social_chart-count:hover {
      opacity: 1; }
  .social_chart span:first-child .social_chart-count {
    border-radius: 50px 0 0 50px; }
  .social_chart span:last-child .social_chart-count {
    border-radius: 0 50px 50px 0; }
  .social_chart span:only-child .social_chart-count {
    border-radius: 50px; }
  .social_chart .social_chart-count.indiv {
    border-radius: 50px; }
  .social_chart .channel-instagram {
    background-color: #F0753A; }
  .social_chart .channel-twitter {
    background-color: #5EA9DD; }
  .social_chart .channel-facebook {
    background-color: #3B5998; }
  .social_chart .channel-youtube {
    background-color: #E62117; }
  .social_chart .channel-pinterest {
    background-color: #BC2029; }

.social_chart-figure {
  width: auto;
  font-size: 15px;
  color: #999999;
  min-width: 0;
  min-height: 24px;
  line-height: 1.2;
  margin: 0;
  padding: 0; }
  .social_chart-figure:not([disabled]):hover {
    background-color: transparent !important;
    cursor: default; }

.gender_split {
  max-width: 420px;
  margin: 10px 0;
  background-color: rgba(20, 174, 125, 0.4); }
  .gender_split .social_chart-count {
    background-color: rgba(20, 174, 125, 0.4);
    border-radius: 50px 0 0 50px !important; }

.masonry-wrapper {
  max-width: 820px;
  margin: 30px 0;
  opacity: 0; }
  .masonry-wrapper .masonry-brick {
    margin: 10px 1%;
    width: 31%;
    box-shadow: 0 0 rgba(0, 0, 0, 0.14), 0 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box; }

.background-shape {
  position: fixed;
  top: 0;
  width: 100%;
  height: 80vh;
  background-size: 100% 100px,3px,auto;
  background-repeat: no-repeat,repeat,no-repeat;
  background-position: bottom,top,center top; }

.background-shape-2 {
  position: fixed;
  height: 70%;
  width: 100%;
  -webkit-transform: skewY(-30deg);
  transform: skewY(-15deg);
  background-color: rgba(255, 0, 67, 0.05); }

form .form-row {
  border-left: 2px solid rgba(255, 0, 67, 0.5);
  padding-left: 20px; }
  form .form-row input {
    min-height: 41px;
    -webkit-transition: all 300ms ease-in-out;
    -khtml-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out; }
    form .form-row input:active, form .form-row input:focus {
      box-shadow: 0 0 3px 0 rgba(255, 0, 67, 0.5);
      border-color: rgba(255, 0, 67, 0.5); }
  form .form-row .columns {
    margin: 0 3% 0 0; }

form .select-list {
  position: relative;
  margin-bottom: 20px; }
  form .select-list .delete {
    position: absolute;
    right: -30px;
    bottom: 5px;
    color: #999999; }

form .radio-group {
  float: left;
  margin-right: 20px; }

trix-toolbar .block_tools {
  display: none; }

trix-editor {
  padding: 15px 10px;
  line-height: 1.5;
  color: #555555 !important;
  background: #FFF;
  min-height: 250px; }

trix-toolbar .button_group button, trix-toolbar .button_group input[type=button] {
  float: left; }

.trix-content a {
  color: #FF0043; }

/* ==========================================================================
	Collection styles                                          
========================================================================== */
#collections-page .page-hero {
  margin-bottom: 0; }

.collection-cover {
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  height: 350px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center; }

.campign-item-stats li {
  float: left;
  margin-right: 10px;
  font-size: 17px; }
  .campign-item-stats li .zmdi {
    margin: 0 5px; }

.chat-icon.active .material-icons {
  color: rgba(255, 0, 67, 0.8); }

.chat-icon, .remove-icon {
  position: absolute;
  right: 10px; }
  .chat-icon .material-icons, .remove-icon .material-icons {
    top: 0;
    font-size: 24px;
    margin: 0;
    color: #CCCCCC; }

.chat-icon span.count {
  background: #FF3232;
  display: block;
  position: absolute;
  top: 9px;
  right: 6px;
  font-size: 8px;
  line-height: 16px;
  height: 14px;
  padding: 0 4px;
  color: #FFFFFF;
  border-radius: 10px;
  z-index: 10; }

.remove-icon {
  top: 30px; }
  .remove-icon .material-icons {
    color: #EFEFEF;
    -webkit-transition: all 0.3s ease-in-out;
    -khtml-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .remove-icon:hover .material-icons {
    color: #CCCCCC; }

.status-0 {
  background-color: #CCCCCC; }

.status-1, .status-hover-1:hover {
  background-color: rgba(246, 143, 74, 0.4) !important;
  color: #FFFFFF; }

.status-2, .status-hover-2:hover {
  background-color: rgba(20, 174, 125, 0.4) !important;
  color: #FFFFFF; }

.status-3, .status-hover-3:hover {
  background-color: rgba(255, 50, 50, 0.5) !important;
  color: #FFFFFF; }

.status-4, .status-hover-4:hover {
  background-color: rgba(246, 143, 74, 0.6) !important;
  color: #FFFFFF; }

.status-5, .status-hover-5:hover {
  background-color: rgba(20, 174, 125, 0.7) !important;
  color: #FFFFFF; }

.status-6, .status-hover-6:hover {
  background-color: rgba(255, 50, 50, 0.6) !important;
  color: #FFFFFF; }

.status-7, .status-hover-7:hover {
  background-color: rgba(246, 143, 74, 0.9) !important;
  color: #FFFFFF; }

.status-8, .status-hover-8:hover {
  background-color: rgba(255, 50, 50, 0.9) !important;
  color: #FFFFFF; }

.status-9, .status-hover-9:hover {
  background-color: rgba(20, 174, 125, 0.9) !important;
  color: #FFFFFF; }

#collection-show .skills li {
  font-size: .9em;
  float: left;
  margin-right: 8px;
  line-height: 1; }

#collection-show #article-main .contact-name a, #collection-show #article-main .contact-name .status {
  display: inline-block; }

#collection-show #article-main .contact-name .status {
  margin-left: 20px;
  position: relative;
  top: -3px; }

#collection-show #article-main .contact-name .declined {
  background-color: rgba(255, 50, 50, 0.8); }

#collection-show #article-main .contact-image {
  position: relative;
  height: 180px;
  margin: 5px 25px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  #collection-show #article-main .contact-image .overlay-options {
    background-color: rgba(20, 174, 125, 0.6);
    position: absolute;
    width: 100%;
    height: 100%;
    color: #FFFFFF;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -khtml-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 10; }
  #collection-show #article-main .contact-image:hover .overlay-options {
    opacity: 1; }
  #collection-show #article-main .contact-image:hover .image-count {
    opacity: 0; }
  #collection-show #article-main .contact-image .add-images, #collection-show #article-main .contact-image .view-images {
    text-align: center;
    font-size: 16px;
    -webkit-transition: all 0.3s ease-in-out;
    -khtml-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    #collection-show #article-main .contact-image .add-images:hover, #collection-show #article-main .contact-image .view-images:hover {
      background-color: rgba(20, 174, 125, 0.7); }
  #collection-show #article-main .contact-image .full .add-images {
    height: 180px; }
  #collection-show #article-main .contact-image .half .add-images, #collection-show #article-main .contact-image .half .view-images {
    height: 90px; }
  #collection-show #article-main .contact-image .image-count {
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgba(32, 32, 32, 0.2);
    color: #FFFFFF;
    padding: 5px;
    -webkit-transition: all 0.3s ease-in-out;
    -khtml-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }

#modal-form.image-slider {
  width: 80vw;
  max-width: 960px;
  padding: 50px 50px 100px; }
  #modal-form.image-slider h2 {
    margin-bottom: 30px; }
  #modal-form.image-slider .slick-slide {
    position: relative; }
    #modal-form.image-slider .slick-slide .image {
      margin: 0 auto;
      min-height: 420px;
      display: block;
      position: relative;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain; }
  #modal-form.image-slider .slick-arrow {
    top: 45%;
    position: absolute;
    font-size: 42px;
    color: #666666; }
    #modal-form.image-slider .slick-arrow:hover {
      color: #FF0043; }
  #modal-form.image-slider .slick-prev {
    left: 15px; }
  #modal-form.image-slider .slick-next {
    right: 15px; }
  #modal-form.image-slider .delete-btn {
    position: absolute;
    bottom: 8px;
    left: calc(50% - 12px); }
  #modal-form.image-slider .loader {
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px); }

#modal-form.contact-note-form {
  padding: 50px 0 80px; }
  #modal-form.contact-note-form form {
    max-width: 520px;
    padding: 0 50px;
    margin-top: 30px; }
    #modal-form.contact-note-form form .button_group button {
      margin: 0; }
    #modal-form.contact-note-form form .trix-content {
      min-height: 120px; }

#article-stats .four {
  min-height: 210px; }

#article-stats .four:first-child, #article-stats .four:nth-child(3) {
  padding-left: 3%; }

#article-stats h3 .lead {
  font-size: 52px;
  font-family: "HelveticaNeueMedium", sans-serif;
  margin-right: 10px; }

#article-stats h3 .sub {
  width: 90px;
  display: inline-block; }

#article-stats li.inline {
  display: inline-block; }
  #article-stats li.inline p {
    margin-bottom: 0; }
    #article-stats li.inline p:after {
      content: "/";
      display: inline-block;
      margin-right: 3px; }
  #article-stats li.inline:last-of-type p:after {
    content: ''; }

#article-main .article-nav p {
  float: left; }

#article-main .article-nav .btn {
  float: right;
  margin: 0; }

#article-main .article-nav .switches {
  display: inline-block;
  margin: 5px 9px; }
  #article-main .article-nav .switches a {
    margin: 2px 3px;
    color: #EFEFEF;
    -webkit-transition: all 0.3s ease-in-out;
    -khtml-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  #article-main .article-nav .switches a.active, #article-main .article-nav .switches a:hover {
    color: #CCCCCC; }

#article-main .editable-text {
  padding: 2px 0;
  border: 1px dotted transparent;
  -webkit-transition: all 0.3s ease-in-out;
  -khtml-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  #article-main .editable-text:hover {
    background-color: rgba(255, 255, 0, 0.2);
    border-color: #EFEFEF; }
    #article-main .editable-text:hover .material-icons {
      display: inline-block; }
  #article-main .editable-text .material-icons {
    display: none; }

.coloured-text .material-icons {
  display: inline-block; }

#note-navigation {
  position: fixed;
  z-index: 1000; }

#campaign-item-notes {
  padding: 0 20px; }
  #campaign-item-notes h3 {
    margin-bottom: 20px; }

.note-block {
  font-size: 14px;
  margin-top: 10px;
  border-bottom: 1px dashed #EFEFEF;
  position: relative; }
  .note-block .image-col .avatar {
    margin: 6px 5px; }
  .note-block .delete-comment i {
    font-size: 17px;
    position: absolute;
    top: 7px;
    right: -7px; }

form.add-comment textarea {
  max-width: 254px; }

.border-column-right {
  position: relative; }
  .border-column-right:after {
    content: ' ';
    height: 100%;
    min-height: 165px;
    width: 2px;
    background-color: rgba(255, 0, 67, 0.8);
    display: inline-block;
    position: absolute;
    top: 0;
    right: -15px; }

.border-column-left {
  border-left: 1px solid #EFEFEF; }

.reduced-list-card {
  position: relative;
  display: block;
  float: left;
  width: 100%;
  border: 1px solid #EFEFEF;
  padding: 20px 20px 16px;
  margin: 10px 1.5%;
  border-radius: 3px;
  overflow: visible;
  box-shadow: 0 0 rgba(0, 0, 0, 0.14), 0 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box; }
  .reduced-list-card .row-one {
    height: 55px; }
  .reduced-list-card .image, .reduced-list-card .info, .reduced-list-card .status {
    float: left;
    display: inline-block; }
  .reduced-list-card .info {
    width: 25%;
    padding: 5px 0;
    margin-right: 2%; }
    .reduced-list-card .info h2 {
      font-size: 16px;
      margin: 0 15px 0 0; }
    .reduced-list-card .info p {
      font-size: 14px;
      color: #777777;
      margin: 0; }
  .reduced-list-card .actions {
    float: right; }
  .reduced-list-card ul.bugs li {
    margin-bottom: 0; }
  .reduced-list-card .image {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin-right: 15px; }
  .reduced-list-card .status {
    width: 53%;
    padding: 5px 0;
    float: left; }
    .reduced-list-card .status ul {
      padding: 4px 0; }
      .reduced-list-card .status ul li {
        position: relative;
        display: inline-block;
        text-align: center;
        margin: 0 5px;
        width: 62px; }
        .reduced-list-card .status ul li .material-icons {
          color: #CCCCCC;
          font-size: 16px; }
        .reduced-list-card .status ul li h4 {
          font-size: 10px;
          margin: 0;
          color: #777777; }
      .reduced-list-card .status ul .pending .material-icons, .reduced-list-card .status ul .pending h4 {
        color: #f68f4a; }
      .reduced-list-card .status ul .active .material-icons, .reduced-list-card .status ul .active h4 {
        color: #14ae7d; }
      .reduced-list-card .status ul .declined .material-icons, .reduced-list-card .status ul .declined h4 {
        color: #FF3232; }
      .reduced-list-card .status ul .status-prompt {
        position: absolute;
        left: calc(50% - 60px);
        width: 120px;
        background: white;
        padding: 10px;
        border: 1px solid #EFEFEF;
        font-size: 12px;
        display: none;
        z-index: 1000;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        box-sizing: border-box;
        border-radius: 2px;
        -webkit-transition: all 0.3s ease-in-out;
        -khtml-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
        .reduced-list-card .status ul .status-prompt:after, .reduced-list-card .status ul .status-prompt:before {
          bottom: 100%;
          left: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none; }
        .reduced-list-card .status ul .status-prompt:after {
          border-color: rgba(255, 255, 255, 0);
          border-bottom-color: #ffffff;
          border-width: 15px;
          margin-left: -15px; }
        .reduced-list-card .status ul .status-prompt:before {
          border-color: rgba(239, 239, 239, 0);
          border-bottom-color: #EFEFEF;
          border-width: 16px;
          margin-left: -16px; }
        .reduced-list-card .status ul .status-prompt a {
          display: block;
          margin: 5px auto;
          padding: 5px 8px;
          background-color: #EFEFEF; }
          .reduced-list-card .status ul .status-prompt a:hover {
            background-color: #F9F9F9; }
      .reduced-list-card .status ul .status-prompt.active {
        display: block; }
  .reduced-list-card .buttons {
    width: 11%;
    float: right; }
    .reduced-list-card .buttons .chat-icon {
      margin: 0;
      display: inline-block;
      right: 20px; }
    .reduced-list-card .buttons i {
      color: #CCCCCC; }
    .reduced-list-card .buttons span.count {
      background: #FF3232;
      display: block;
      position: absolute;
      top: 5px;
      right: 3px;
      font-size: 8px;
      line-height: 16px;
      height: 14px;
      padding: 0 4px;
      color: #FFFFFF;
      border-radius: 10px;
      z-index: 10; }
    .reduced-list-card .buttons .md-button.md-icon-button {
      border-radius: 0; }
    .reduced-list-card .buttons .md-button:not([disabled]).md-focused {
      background-color: transparent !important; }

/* ==========================================================================
	Report pages                                      
========================================================================== */
.report-overview-page {
  margin-top: 30px; }
  .report-overview-page .article-hero {
    height: 400px; }

#report-page .right-col {
  padding-left: 55px; }

#report-page .push-right {
  width: calc(100% - 220px);
  margin-left: 220px; }

#report-page #article-head .article-hero {
  height: 620px; }
  #report-page #article-head .article-hero:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    background: -webkit-linear-gradient(transparent, #202020);
    background: -o-linear-gradient(transparent, #202020);
    background: -moz-linear-gradient(transparent, #202020);
    background: linear-gradient(transparent, #202020); }

#report-page #article-head .article-info {
  position: absolute;
  bottom: 20%;
  width: 100%; }
  #report-page #article-head .article-info h1, #report-page #article-head .article-info h2, #report-page #article-head .article-info h3, #report-page #article-head .article-info h4 {
    color: #FFFFFF; }
  #report-page #article-head .article-info h1 {
    font-size: 3.6em; }

#report-page .article-content {
  margin: 50px 0; }
  #report-page .article-content .article-subheader {
    font-size: 26px;
    padding: 0; }
    #report-page .article-content .article-subheader .h2-tag {
      font-size: 1em;
      border-bottom: 2px solid #FF0043;
      display: inline-block;
      margin-top: 30px; }
  #report-page .article-content .article-block {
    margin-bottom: 30px; }
  #report-page .article-content .article-text {
    padding-top: 0; }
  #report-page .article-content .article-image img {
    max-width: 720px;
    box-shadow: 0 0 rgba(0, 0, 0, 0.14), 0 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box; }
  #report-page .article-content .article-video .video-thumb {
    display: none; }
  #report-page .article-content .blockquote {
    margin: 50px auto;
    font-size: 1em;
    max-width: 720px; }
    #report-page .article-content .blockquote:before {
      width: 5px;
      height: 100%;
      background-color: #FF0043;
      left: -20px;
      transform: rotate(0deg);
      top: 0px; }
    #report-page .article-content .blockquote h3 {
      color: #555555; }
    #report-page .article-content .blockquote h4 {
      color: #999999; }
      #report-page .article-content .blockquote h4:before {
        background-color: #999999; }
  #report-page .article-content .article-person .image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 420px;
    margin-bottom: 20px;
    position: relative; }
  #report-page .article-content .article-person .image:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    background: -webkit-linear-gradient(transparent, transparent, #202020);
    background: -o-linear-gradient(transparent, transparent, #202020);
    background: -moz-linear-gradient(transparent, transparent, #202020);
    background: linear-gradient(transparent, transparent, #202020);
    border-bottom: 10px solid #FF0043; }
  #report-page .article-content .article-person .title {
    position: absolute;
    bottom: 30px;
    left: 35px;
    font-size: 2.2em;
    color: #FFFFFF;
    z-index: 10;
    width: 75%; }
  #report-page .article-content .article-person .text {
    margin-top: 35px; }
    #report-page .article-content .article-person .text a {
      color: #FF0043; }
  #report-page .article-content .article-person .socials {
    background-color: rgba(255, 255, 255, 0.6);
    -webkit-transition: 2px;
    -khtml-transition: 2px;
    -moz-transition: 2px;
    -ms-transition: 2px;
    -o-transition: 2px;
    border-radius: 2px;
    padding: 0 20px; }
    #report-page .article-content .article-person .socials .super-size {
      margin-top: 5px; }
    #report-page .article-content .article-person .socials .columns {
      margin: 10px 1.5%; }
  #report-page .article-content .article-code iframe, #report-page .article-content .article-code .twitter-tweet {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    border-radius: 2px;
    display: block;
    background-color: #FFFFFF;
    max-width: 600px;
    margin: 0 auto; }
  #report-page .article-content .article-code #twitter-embed div[ng-bind-html="neText"] {
    display: none; }
  #report-page .article-content .article-relationship {
    padding: 50px 0; }
    #report-page .article-content .article-relationship .image {
      width: 100%;
      height: 360px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat; }
    #report-page .article-content .article-relationship .text h2 {
      font-size: 1.5em;
      padding-top: 10px; }

#report-page .report-contents h2 {
  margin-bottom: 20px; }

#report-page .report-contents .count-block {
  padding: 0; }

#report-page #article-contents-navigation {
  z-index: 100;
  width: 220px;
  height: 100%; }
  #report-page #article-contents-navigation .trigger {
    margin-bottom: 10px; }
    #report-page #article-contents-navigation .trigger .label {
      position: relative;
      top: -6px;
      margin-left: 5px; }
  #report-page #article-contents-navigation .count-block ol > li {
    margin-bottom: 20px;
    padding-left: 8px; }

/* ==========================================================================
	Modals and forms                                          
========================================================================== */
.contact-note-form form textarea {
  max-width: 350px;
  min-height: 200px; }

.image-form .image {
  width: 150px;
  min-height: 130px;
  background-color: #F9F9F9;
  margin: 10px auto;
  position: relative;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 2px;
  -webkit-transition: all 0.3s ease-in-out;
  -khtml-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .image-form .image:hover {
    box-shadow: 0 0 rgba(0, 0, 0, 0.14), 0 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box; }
  .image-form .image .material-icons {
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px); }

md-list {
  max-height: 320px;
  overflow: auto; }
  md-list .contributor button {
    padding: 0 5px; }
  md-list .contact-item, md-list .md-list-item-text h3 {
    color: #666666 !important; }

.contact-auto-form {
  background-color: #FFFFFF; }

.autocomplete {
  margin-bottom: 15px;
  border: 1px solid #EFEFEF; }
  .autocomplete .md-whiteframe-1dp, .autocomplete .md-whiteframe-z1 {
    box-shadow: none; }

.autocomplete-item .avatar {
  margin: 5px 15px 0 0;
  display: block; }

.autocomplete-item .text, .autocomplete-item .image {
  float: left; }

.autocomplete-item .text {
  margin-top: 8px; }
  .autocomplete-item .text h4 {
    margin: 0;
    line-height: 1; }
  .autocomplete-item .text p {
    margin: 0;
    font-size: 13px; }
  .autocomplete-item .text ul.inline-items {
    margin: 0;
    padding: 0; }
    .autocomplete-item .text ul.inline-items li {
      display: inline-block;
      padding: 0;
      margin: 5px 10px 0 0;
      line-height: 1.3;
      color: #888888;
      font-size: 12px; }

.md-autocomplete-suggestions li {
  border-bottom: 1px solid #EEEEEE; }

.md-autocomplete-suggestions .md-whiteframe-1dp, .md-autocomplete-suggestions .md-whiteframe-z1 {
  box-shadow: none !important; }

h2.super-size {
  position: relative; }
  h2.super-size .count {
    float: left;
    width: 80px; }
  h2.super-size a {
    float: left;
    width: calc(100% - 100px); }
    h2.super-size a:hover {
      color: #FF0043; }

.bg {
  position: absolute;
  top: 130%;
  height: 100%;
  width: 200%;
  left: -50%;
  margin: 0 auto;
  background-color: #FFFDF0;
  transform: rotate(8deg);
  z-index: 0; }

.chart-wrapper {
  height: 100%;
  width: 100%;
  box-sizing: border-box; }

.checkbox {
  margin: 5px 0; }
  .checkbox input[type="checkbox"], .checkbox label {
    display: inline-block;
    width: auto; }
  .checkbox input[type="checkbox"] {
    margin-right: 10px; }
  .checkbox label {
    font-size: 16px;
    color: #555555; }

.protein-monitor-logo-black {
  background-image: url("https://protein-global-assets.s3.amazonaws.com/protein-monitor/protein_monitor_black.svg");
  height: 40px;
  width: 200px;
  background-size: contain; }

.box-out {
  -webkit-transition: 2px;
  -khtml-transition: 2px;
  -moz-transition: 2px;
  -ms-transition: 2px;
  -o-transition: 2px;
  border-radius: 2px;
  background: #FFFFFF;
  padding: 15px;
  border: 1px solid #CCCCCC; }
  .box-out label, .box-out p {
    font-size: 1em; }
  .box-out label {
    margin: 15px 0 3px; }
  .box-out p {
    margin: 3px 0; }

.back-a-page {
  position: absolute;
  left: 50px; }

/* ==========================================================================
	Map
========================================================================== */
.fullwidth .wrapper-sm, .fullwidth iframe {
  width: 95% !important;
  max-width: 95% !important;
  height: 90vh !important; }
